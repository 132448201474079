@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Circular Pro';
    src:
        url('/fonts/CircularStd-Light.woff2') format('woff2'),
        url('/fonts/CircularStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Pro';
    src:
        url('/fonts/circularstd-regular.woff2') format('woff2'),
        url('/fonts/circularstd-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* 
@font-face {
  font-family: "Circular Pro";
  src: url("/fonts/CircularStd-Book.woff2") format("woff2"),
    url("/fonts/CircularStd-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
} */

@font-face {
    font-family: 'Circular Pro';
    src:
        url('/fonts/CircularStd-Medium.woff2') format('woff2'),
        url('/fonts/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Pro';
    src:
        url('/fonts/CircularStd-Bold.woff2') format('woff2'),
        url('/fonts/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Pro';
    src:
        url('/fonts/CircularStd-Black.woff2') format('woff2'),
        url('/fonts/CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Cheltenham Std';
    src:
        url('/fonts/CheltenhamStd-BoldCond.woff2') format('woff2'),
        url('/fonts/CheltenhamStd-BoldCond.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Cheltenham Std';
    src:
        url('/fonts/CheltenhamStd-BookCond.woff2') format('woff2'),
        url('/fonts/CheltenhamStd-BookCond.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Cheltenham Std';
    src:
        url('/fonts/CheltenhamStd-LightCond.woff2') format('woff2'),
        url('/fonts/CheltenhamStd-LightCond.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Cheltenham Std';
    src:
        url('/fonts/CheltenhamStd-UltraCond.woff2') format('woff2'),
        url('/fonts/CheltenhamStd-UltraCond.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Cheltenham Std Book';
    src:
        url('/fonts/CheltenhamStd-Book.woff2') format('woff2'),
        url('/fonts/CheltenhamStd-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Circular Pro', sans-serif;
    background-color: #f8f8f8;
}

.sb-show-main.sb-main-padded {
    padding: 0;
}

.hideArrow::-webkit-outer-spin-button,
.hideArrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #fcfcfc inset;
}

.hideArrow::-webkit-outer-spin-button,
.hideArrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Date picker START */

.react-datepicker__triangle {
    display: none;
}

.react-datepicker {
    border-color: #6b6b80 !important;
    border-radius: 4px !important;
}

.react-datepicker__navigation-icon--previous::before {
    border-color: #4b4b63 !important;
    border-width: 2px 2px 0px 0px !important;
}

.react-datepicker__navigation-icon--next::before {
    border-color: #4b4b63 !important;
    border-width: 2px 2px 0px 0px !important;
}

.react-datepicker__current-month,
.react-datepicker-year-header {
    color: #1e3a8a !important;
    font-weight: 400 !important;
    margin: 1rem 0 1.5rem 0 !important;
    font-size: 14px !important;
    line-height: 20px;
    font-family: inherit;
}

.react-datepicker__navigation {
    top: 25px !important;
}

.react-datepicker__navigation--next {
    right: 20px !important;
}

.react-datepicker__navigation--previous {
    left: 20px !important;
}

.react-datepicker__header {
    background-color: #fcfcfc !important;
    border-bottom: none !important;
}

.react-datepicker__month {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem !important;
    margin: 0 !important;
    background-color: #fcfcfc !important;
    border-radius: 0 0 0.3rem 0.3rem;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    color: #4b4b63;
    text-decoration: line-through;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 2rem !important;
    line-height: 2rem !important;
}

.react-datepicker__day-name {
    color: #4b4b63 !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    background-color: #e5e5eb !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    background-color: #d1d1db !important;
    border-radius: 0.3rem !important;
    font-weight: normal !important;
    color: #14142b !important;
}

.react-datepicker__day--selected:hover {
    color: #14142b !important;
}

.react-datepicker__day--selected {
    background-color: #1e3a8a !important;
    color: #fcfcfc !important;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 0px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #1e3a8a !important;
    color: #fcfcfc !important;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 0px !important;
    margin: 0px !important;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    min-width: 106px;
    width: 106px !important;
    padding: 17px 18px;
    border-radius: 0px !important;
    font-weight: 400 !important;
}

.react-datepicker.react-datepicker--time-only {
    min-width: 150px;
    width: 100%;
}

.react-datepicker__time-list-item {
    font-size: 14px;
    font-weight: 400;
}

.react-datepicker__time-list-item--selected {
    background-color: #1e3a8a !important;
}

.react-datepicker__time-container,
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
    width: 100% !important;
}

ul.react-datepicker__time-list {
    padding-bottom: 6px;
}

/* ::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #e5e5eb;
}

::-webkit-scrollbar-thumb {
    background: #9d5cf3;
    height: 320px;
    border-radius: 12px;
} */

/* Date picker END */

/* input Range */
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']::-webkit-slider-thumb {
    width: 0;
    height: 0;
    -webkit-appearance: none;
}

input[type='range']::-moz-range-thumb {
    width: 0;
    height: 0;
}

input[type='range']::-ms-thumb {
    width: 0;
    height: 0;
}

/* rating */

.rating {
    display: flex;
}

.star {
    cursor: pointer;
    font-size: 24px;
}

.filled {
    color: gold;
}

.half-filled {
    position: relative;
}

.half-filled::before {
    content: '★';
    position: absolute;
    left: 0;
    overflow: hidden;
    width: 50%;
    color: gold;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

iframe {
    border: none;
}

/* This css is only applied when fullscreen is active. */
.player:fullscreen {
    max-width: none;
    width: 100%;
}

.player:-webkit-full-screen {
    max-width: none;
    width: 100%;
}

.player__video {
    width: 100%;
}

.player__slider {
    width: 4px;
    height: 78px;
    -webkit-appearance: slider-vertical;
    background-color: #1e3a8a;
    display: none;
}

.player input[type='range'] {
    -webkit-appearance: slider-vertical;
    -moz-appearance: slider-vertical;
    accent-color: #9d5cf3;
}

.volume-container:hover .player__slider {
    display: block;
}

/* thumbGallery-thumb */
.thumbGallery-thumb .swiper-slide {
    width: auto !important;
}

/* CustomScrollbar.css */

.scrollbar-container .ps__thumb-x {
    height: 8px !important;
}

.scrollbar-container .ps__thumb-y {
    width: 12px;
}

.scrollbar-container .ps__thumb-x,
.scrollbar-container .ps__thumb-x:hover,
.scrollbar-container .ps__rail-x:hover>.ps__thumb-x,
.scrollbar-container .ps__thumb-y,
.scrollbar-container .ps__thumb-y:hover,
.scrollbar-container .ps__rail-y:hover>.ps__thumb-y {
    background-color: #9d5cf3 !important;
    transition: none;
}

.scrollbar-container.ps--active-x {
    padding-bottom: 32px;
}

.scrollbar-container.ps--active-x>.ps__rail-x,
.scrollbar-container.ps--active-y>.ps__rail-y {
    background: #eeeeee;
    background-color: #eeeeee;
}

.scrollbar-container .scrollbar-container:hover .ps__rail-x,
.scrollbar-container .scrollbar-container:hover .ps__rail-y {
    opacity: 1;
}

.scrollbar-container .ps__rail-x,
.scrollbar-container .ps__rail-y {
    opacity: 1 !important;
    z-index: 1000;
    transition: none;
}

.scrollbar-container .ps__rail-x {
    height: 12px;
}

.scrollbar-container .ps .ps__rail-x:hover,
.scrollbar-container .ps .ps__rail-y:hover {
    background: transparent;
}

/* Custom scrollbar styling */
.custom-scrollbar {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 10px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #eee;
    background-color: #eee;
    border-radius: 8px;

}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #9d5cf3;
    border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #9d5cf3;
}